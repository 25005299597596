.gridLineHorizontal {
  --background: #ffffff;
  --color: rgba(0, 0, 0, 0.2);
  --height: 1px;
  --width: 5px;
  --fade-stop: 90%;
  /* Bleed in or out from the container */
  --offset: -100px;

  position: absolute;
  width: calc(100% + var(--offset));
  height: var(--height);
  left: calc(var(--offset) / 2 * -1);

  background: linear-gradient(
    to right,
    var(--color),
    var(--color) 50%,
    transparent 0,
    transparent
  );
  background-size: var(--width) var(--height);

  /* Fade out the edges */
  mask: linear-gradient(
      to left,
      var(--background) var(--fade-stop),
      transparent
    ),
    linear-gradient(to right, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  -webkit-mask: linear-gradient(
      to left,
      var(--background) var(--fade-stop),
      transparent
    ),
    linear-gradient(to right, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: exclude;
  z-index: 20;
}

.gridLineHorizontalDark {
  --background: #ffffff;
  --color: rgba(255, 255, 255, 0.2);
  --height: 1px;
  --width: 5px;
  --fade-stop: 90%;
  /* Bleed in or out from the container */
  --offset: -100px;

  position: absolute;
  width: calc(100% + var(--offset));
  height: var(--height);
  left: calc(var(--offset) / 2 * -1);

  background: linear-gradient(
    to right,
    var(--color),
    var(--color) 50%,
    transparent 0,
    transparent
  );
  background-size: var(--width) var(--height);

  /* Fade out the edges */
  mask: linear-gradient(
      to left,
      var(--background) var(--fade-stop),
      transparent
    ),
    linear-gradient(to right, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  -webkit-mask: linear-gradient(
      to left,
      var(--background) var(--fade-stop),
      transparent
    ),
    linear-gradient(to right, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: exclude;
  z-index: 20;
}

.gridLineVertical {
  --background: #ffffff;
  --color: rgba(0, 0, 0, 0.2);
  --height: 5px;
  --width: 1px;
  --fade-stop: 90%;
  --offset: -100px;

  position: absolute;
  height: calc(100% + var(--offset));
  width: var(--width);
  top: calc(var(--offset) / 2 * -1);

  background: linear-gradient(
    to bottom,
    var(--color),
    var(--color) 50%,
    transparent 0,
    transparent
  );
  background-size: var(--width) var(--height);

  mask: linear-gradient(to top, var(--background) var(--fade-stop), transparent),
    linear-gradient(to bottom, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  -webkit-mask: linear-gradient(
      to top,
      var(--background) var(--fade-stop),
      transparent
    ),
    linear-gradient(to bottom, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: exclude;
  z-index: 20;
}

.gridLineVerticalDark {
  --background: #ffffff;
  --color: rgba(255, 255, 255, 0.2);
  --height: 5px;
  --width: 1px;
  --fade-stop: 90%;
  --offset: -100px;

  position: absolute;
  height: calc(100% + var(--offset));
  width: var(--width);
  top: calc(var(--offset) / 2 * -1);

  background: linear-gradient(
    to bottom,
    var(--color),
    var(--color) 50%,
    transparent 0,
    transparent
  );
  background-size: var(--width) var(--height);

  mask: linear-gradient(to top, var(--background) var(--fade-stop), transparent),
    linear-gradient(to bottom, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  -webkit-mask: linear-gradient(
      to top,
      var(--background) var(--fade-stop),
      transparent
    ),
    linear-gradient(to bottom, var(--background) var(--fade-stop), transparent),
    linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: exclude;
  z-index: 20;
}
